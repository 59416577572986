import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 769.000000 904.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,904.000000) scale(0.100000,-0.100000)">
<path d="M3788 7409 c-47 -13 -113 -84 -127 -135 -27 -96 -3 -195 58 -247 41
-34 65 -40 150 -35 56 3 75 9 105 32 110 83 126 226 36 316 -68 68 -143 91
-222 69z"/>
<path d="M3725 6553 c-506 -21 -1022 -197 -1430 -487 -200 -142 -438 -368
-577 -548 -259 -333 -430 -700 -517 -1109 -75 -353 -74 -765 5 -1128 152 -709
588 -1328 1214 -1725 105 -67 374 -197 506 -245 684 -248 1451 -205 2109 120
301 148 526 315 775 575 107 112 122 130 207 245 226 307 391 674 466 1035 46
222 52 285 52 579 -1 306 -12 406 -75 655 -262 1042 -1138 1838 -2205 2004
-95 15 -397 38 -440 34 -11 -1 -51 -3 -90 -5z m450 -688 c326 -49 587 -158
899 -379 174 -122 325 -281 466 -490 99 -149 152 -249 210 -399 68 -175 63
-159 101 -347 28 -141 33 -192 37 -352 2 -108 -1 -231 -7 -290 -24 -209 -112
-501 -211 -698 -83 -164 -318 -473 -448 -588 -68 -60 -279 -212 -362 -260 -24
-15 -137 -64 -250 -109 l-205 -83 -285 -40 c-157 -22 -316 -40 -355 -40 -172
0 -634 129 -841 236 -210 107 -373 231 -560 426 -434 451 -631 1069 -538 1693
99 660 513 1230 1109 1528 204 102 434 173 636 196 52 6 108 13 124 15 60 9
382 -4 480 -19z m-1419 -3877 l46 -23 -6 -100 c-4 -55 -10 -115 -13 -134 l-6
-35 106 83 106 82 50 -25 c31 -16 51 -21 53 -15 3 8 20 5 55 -7 l51 -18 -66
-194 c-74 -214 -65 -203 -144 -173 l-28 10 60 172 c34 95 59 176 58 179 -2 4
-84 -53 -183 -125 -155 -114 -183 -131 -198 -122 -10 7 -19 15 -19 20 -2 16
25 447 28 447 2 0 24 -10 50 -22z m2245 -210 l160 -153 -51 -25 -50 -24 -31
31 -31 31 -79 -37 -79 -36 0 -46 c0 -44 -1 -46 -46 -67 -26 -13 -48 -22 -49
-20 -2 2 -6 104 -10 228 l-7 225 49 22 c26 11 52 22 56 22 5 1 80 -68 168
-151z m-381 -4 c19 -49 14 -57 -33 -51 -84 12 -168 -27 -133 -61 7 -8 48 -18
89 -22 42 -5 89 -16 105 -24 59 -31 79 -129 35 -179 -34 -37 -113 -67 -181
-67 -62 0 -63 0 -78 35 -7 19 -14 38 -14 43 0 5 36 9 80 8 70 -1 82 2 102 21
20 20 21 25 7 38 -8 8 -46 19 -84 24 -88 11 -117 23 -144 57 -28 35 -28 102
-1 137 34 42 97 67 173 67 63 0 68 -2 77 -26z m-1159 -40 c30 -9 60 -21 68
-27 8 -7 42 -10 83 -8 l69 2 -7 -90 c-4 -50 -5 -91 -2 -91 2 0 35 38 73 85
l68 85 60 0 c68 0 72 13 -30 -111 l-63 -76 86 -112 c47 -62 84 -115 81 -118
-4 -3 -34 -2 -69 1 l-63 7 -70 95 c-46 63 -71 89 -72 77 -1 -10 -5 -53 -8 -95
-7 -76 -7 -78 -33 -78 -15 0 -38 3 -51 7 l-24 6 5 161 c3 89 4 154 1 145 -3
-14 -12 -12 -53 16 -27 18 -67 36 -88 40 -83 14 -152 -46 -152 -133 0 -87 97
-141 202 -114 23 6 44 10 46 8 1 -2 0 -22 -4 -44 -8 -49 -21 -56 -109 -55
-105 1 -184 47 -223 131 -19 43 -23 64 -19 102 18 153 143 230 298 184z m778
-40 c121 -61 155 -216 70 -322 -40 -50 -94 -75 -173 -79 -61 -4 -77 -1 -116
19 -84 46 -129 144 -109 238 31 138 194 210 328 144z"/>
<path d="M3610 5734 c-360 -33 -763 -236 -1084 -544 -436 -419 -658 -1101
-541 -1661 50 -239 159 -524 262 -687 308 -485 805 -802 1376 -878 145 -19
379 -14 527 12 203 35 474 128 619 213 77 45 267 185 386 284 279 232 532 734
585 1160 18 142 8 423 -20 562 -64 320 -235 660 -457 910 -89 100 -208 203
-357 310 -127 90 -448 240 -546 256 -30 4 -102 20 -160 35 -95 24 -125 26
-325 29 -121 1 -240 1 -265 -1z m1770 -1132 c39 -5 55 -11 55 -22 0 -12 -22
-17 -105 -22 -231 -17 -426 -70 -635 -173 -426 -210 -746 -537 -1153 -1180
-168 -265 -143 -245 -305 -245 l-133 0 -312 793 c-171 435 -314 800 -318 810
-6 16 4 17 129 17 l136 0 19 -27 c57 -81 146 -295 342 -828 73 -198 140 -376
148 -395 l14 -35 24 30 c14 17 62 88 107 158 267 416 337 509 517 687 211 209
413 327 681 400 159 43 239 50 494 44 132 -3 265 -9 295 -12z m-1290 -130 l0
-107 -127 -100 c-70 -55 -130 -101 -135 -103 -4 -2 -8 91 -8 207 l0 211 135 0
135 0 0 -108z m571 -1027 l542 -470 -133 -7 c-121 -6 -138 -5 -178 12 -77 35
-208 127 -564 397 -124 95 -229 173 -232 173 -3 0 -6 -133 -6 -295 l0 -295
-135 0 -135 0 0 298 0 297 27 40 c14 22 65 87 113 145 47 58 99 122 115 143
15 21 31 36 36 35 4 -2 252 -214 550 -473z"/>
<path d="M4830 1725 c0 -84 1 -85 69 -54 l45 20 -54 54 c-30 30 -56 55 -57 55
-2 0 -3 -34 -3 -75z"/>
<path d="M4068 1612 c-30 -18 -58 -67 -58 -101 0 -40 32 -102 61 -116 41 -22
101 -19 132 6 15 11 32 38 38 60 34 112 -79 210 -173 151z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
